import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import { validate as validateEmail } from "email-validator";
import { useIntl } from "hooks";
import * as R from "ramda";
import React from "react";
import messages from "../messages";
import { MAX_ADDRESSES } from "../index";

export const isValidEmailAddressInput = (input) => {
  const addresses = input.split(",");
  const trim = (address) => {
    return address.trim();
  };
  return (
    addresses.length <= MAX_ADDRESSES &&
    R.all(R.pipe(trim, validateEmail))(addresses)
  );
};

export const EmailButton = ({
  onClick,
  addresses,
  setAddressInputError,
  recaptchaToken,
  setRecaptchaIncomplete,
  disabled,
}) => {
  const intl = useIntl();
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          const emailValid = isValidEmailAddressInput(addresses);
          if (emailValid && recaptchaToken) {
            onClick();
          } else {
            if (!emailValid) {
              setAddressInputError(true);
            }
            if (!recaptchaToken) {
              setRecaptchaIncomplete(true);
            }
          }
        }}
        disabled={disabled}
      >
        {intl.formatMessage(messages.send)}
      </Button>
    </>
  );
};
