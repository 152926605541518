import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {PlatformApp, useNavigate as usePlatformNavigate,} from "@platform/cj-platform-navigation";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import messages from "components/Layout/messages";
import {Pendo} from "components/Pendo";
import {PrivateRoutes} from "components/PrivateRoutes";
import {Spinner} from "components/Spinner";
import {
  DEFAULT_PATH,
  PUBLISHER_FAVORITES_PATH,
  PUBLISHER_PROFILE_PATH_PATTERN,
  PUBLISHER_PROFILE_PROPERTIES_LIST_PATH_PATTERN,
  PUBLISHER_PROFILE_PROPERTY_DETAIL_PATH_PATTERN,
  PUBLISHER_SEARCH_PATH,
} from "constants/paths";
import * as userTypes from "constants/userTypes";
import {Nav2022Provider} from "context/Nav2022Context";
import {LegacyViewProvider} from "context/ViewContext/LegacyViewContext";
import {Nav2022ViewProvider} from "context/ViewContext/Nav2022ViewContext";
import {PlatformNavigationProvider} from "context/ViewContext/PlatformNavigationContext";
import {SearchTermsProvider} from "context/ViewContext/SearchTermsContext";
import {standardizeUser, useFeatures, useUserQuery} from "hooks";
import React, {useEffect} from "react";
import {generatePath} from "react-router";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {
  advertiserCanAccessRecruitTab,
  hasAuthorizedRole,
  userIsAdvertiser,
  userIsPublisher,
} from "utils/authorizationUtils";
import {
  accountsClient as accountssApolloClient,
  clientOAuth2,
  partnersClient as partnersApolloClient,
} from "utils/clients";
import {translations} from "utils/intl";
import {Login} from "views/Login";
import {OAuthCallback} from "views/OAuthCallback";
import {Profile} from "views/PublishersProfile";
import {PublishersSearch} from "views/PublishersSearch";
import {NewNavFilters} from "views/PublishersSearch/FiltersPanel";
import {FavoritePartnersTable, PartnersTable,} from "views/PublishersSearch/PartnersTable";
import {SearchBar} from "views/PublishersSearch/SearchBar";
import {AppProvider} from "../context";
import {OVERVIEW_TAB_INDEX, PROPERTIES_TAB_INDEX,} from "../views/PublishersProfile/PublisherTabs/tabIndex";
import "./styles.css";
import {ErrorPage} from "@cjdev-internal/visual-stack-x/ErrorPage";

export const redirectToMemberUrl = () =>
  (window.location.href = process.env.REACT_APP_MEMBER_URL);

export const PrivateApp = () => {
  const { data: legacyUser, isLoading: isLoadingUser } = useUserQuery();
  const user = standardizeUser(legacyUser);

  const companyId = user.currentCompany.id;
  const { data: features, isLoading: isLoadingFeatures } = useFeatures(
    companyId,
    {
      enabled: !isLoadingUser,
    }
  );

  if (isLoadingFeatures || isLoadingUser) {
    return (
      <Box align="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Pendo />
      <Switch>
        {hasAuthorizedRole(user, [
          userTypes.CLIENT_AS_ADVERTISER,
          userTypes.EMPLOYEE_AS_ADVERTISER,
          userTypes.EMPLOYEE_AS_SELF,
        ]) && (
            <Route exact path="/">
              <Redirect to={DEFAULT_PATH} />
            </Route>
          )}

        {hasAuthorizedRole(user, [
          userTypes.CLIENT_AS_PUBLISHER,
          userTypes.EMPLOYEE_AS_PUBLISHER,
        ]) && (
            <Route exact path="/">
              <Redirect
                to={generatePath(PUBLISHER_PROFILE_PATH_PATTERN, {
                  id: companyId,
                })}
              />
            </Route>
          )}

        {hasAuthorizedRole(user, [
          userTypes.CLIENT_AS_ADVERTISER,
          userTypes.EMPLOYEE_AS_ADVERTISER,
          userTypes.EMPLOYEE_AS_SELF,
        ]) && (
            <Route exact path={PUBLISHER_SEARCH_PATH}>
              <LegacyViewProvider features={features} user={user}>
                <PublishersSearch
                  partnersTable={<PartnersTable />}
                  onCancel={() => {
                    redirectToMemberUrl();
                  }}
                />
              </LegacyViewProvider>
            </Route>
          )}

        <Route exact path={PUBLISHER_PROFILE_PATH_PATTERN}>
          <LegacyViewProvider features={features} user={user}>
            <Profile
              onCancel={() => {
                redirectToMemberUrl();
              }}
              tabIndex={OVERVIEW_TAB_INDEX}
            />
          </LegacyViewProvider>
        </Route>

        <Route exact path={PUBLISHER_PROFILE_PROPERTY_DETAIL_PATH_PATTERN}>
          <LegacyViewProvider features={features} user={user}>
            <Profile
              onCancel={() => {
                window.location.href = process.env.REACT_APP_MEMBER_URL;
              }}
              tabIndex={PROPERTIES_TAB_INDEX}
            />
          </LegacyViewProvider>
        </Route>

        <Route exact path={PUBLISHER_PROFILE_PROPERTIES_LIST_PATH_PATTERN}>
          <LegacyViewProvider features={features} user={user}>
            <Profile
              onCancel={() => {
                redirectToMemberUrl();
              }}
              tabIndex={PROPERTIES_TAB_INDEX}
            />
          </LegacyViewProvider>
        </Route>

        {hasAuthorizedRole(user, [
          userTypes.CLIENT_AS_ADVERTISER,
          userTypes.EMPLOYEE_AS_ADVERTISER,
        ]) && (
            <Route exact path={PUBLISHER_FAVORITES_PATH}>
              <LegacyViewProvider features={features} user={user}>
                <PublishersSearch
                  partnersTable={<FavoritePartnersTable />}
                  onCancel={() => {
                    redirectToMemberUrl();
                  }}
                />
              </LegacyViewProvider>
            </Route>
          )}

        <Route path="*">
          {user && (
            <ErrorPage errorCode={403} />
          )}
        </Route>
      </Switch>
    </>
  );
};

export const LegacyNavigationApp = ({
  accountsClient = accountssApolloClient,
  partnersClient = partnersApolloClient,
  Router = BrowserRouter,
}) => {
  const queryClient = new QueryClient();

  return (
    <Nav2022Provider nav2022={false}>
      <Router>
        <QueryClientProvider
          client={queryClient}
          config={{ queries: { refetchOnWindowFocus: false } }}
        >
          <AppProvider
            partnersClient={partnersClient}
            accountsClient={accountsClient}
          >
            <Switch>
              <Route path="/login">
                <Login
                  clientOAuth2={clientOAuth2}
                  memberUrl={process.env.REACT_APP_MEMBER_URL}
                />
              </Route>

              <Route path="/oauth-callback">
                <OAuthCallback clientOAuth2={clientOAuth2} />
              </Route>

              <PrivateRoutes path="/">
                <PrivateApp />
              </PrivateRoutes>
            </Switch>
            <div id="footer-placeholder"></div>
          </AppProvider>
        </QueryClientProvider>
      </Router>
    </Nav2022Provider>
  );
};

export const Nav2022App = () => {
  const routes = [
    {
      path: "/",
      titleMessage: {
        ...messages.appTitle,
      },
      Content: ({ user }) => {
        const navigate = usePlatformNavigate();

        useEffect(() => {
          if (userIsPublisher(user)) {
            const companyId = user?.currentCompany?.id;
            const path = generatePath(PUBLISHER_PROFILE_PATH_PATTERN, {
              id: companyId,
            });
            navigate(path);
          } else {
            navigate(PUBLISHER_SEARCH_PATH);
          }
        }, [user, navigate]);

        return <></>;
      },
    },
    {
      path: PUBLISHER_SEARCH_PATH,
      titleMessage: {
        ...messages.appTitle,
      },
      Filters: ({ user }) => {
        if(!advertiserCanAccessRecruitTab(user)) {
          return null;
        }
        return (
          <PlatformNavigationProvider user={user}>
            <NewNavFilters user={user} />
          </PlatformNavigationProvider>
        )
      },
      Dimensions: ({ user }) => {
        if(userIsAdvertiser(user) && !advertiserCanAccessRecruitTab(user)) {
          return null;
        }
        return (
          <PlatformNavigationProvider user={user}>
            <SearchTermsProvider>
              <SearchBar />
            </SearchTermsProvider>
          </PlatformNavigationProvider>
        );
      },
      Content: ({ user }) => {
        const navigate = usePlatformNavigate();
        useEffect(() => {
          if (userIsPublisher(user)) {
            const companyId = user?.currentCompany?.id;
            const path = generatePath(PUBLISHER_PROFILE_PATH_PATTERN, {
              id: companyId,
            });
            navigate(path);
          }
        }, [user, navigate]);

        if(!advertiserCanAccessRecruitTab(user)) {
          return <ErrorPage errorCode={403} />;
        }

        return (
          <Nav2022ViewProvider user={user}>
            <PublishersSearch
              partnersTable={<PartnersTable />}
              onCancel={() => {
                redirectToMemberUrl();
              }}
            />
          </Nav2022ViewProvider>
        );
      },
    },
    {
      path: PUBLISHER_FAVORITES_PATH,
      titleMessage: {
        ...messages.appTitle,
      },
      Filters: ({ user }) => {
        if(!advertiserCanAccessRecruitTab(user)) {
          return null;
        }
        return (
          <PlatformNavigationProvider user={user}>
            <NewNavFilters user={user} />
          </PlatformNavigationProvider>
        )
      },
      Dimensions: ({ user }) => {
        if(!advertiserCanAccessRecruitTab(user)) {
          return null;
        }
        return (
          <PlatformNavigationProvider user={user}>
            <SearchTermsProvider>
              <SearchBar />
            </SearchTermsProvider>
          </PlatformNavigationProvider>
        );
      },
      Content: ({ user }) => {
        const navigate = usePlatformNavigate();

        useEffect(() => {
          if (userIsPublisher(user)) {
            const companyId = user?.currentCompany?.id;
            const path = generatePath(PUBLISHER_FAVORITES_PATH, {
              id: companyId,
            });
            navigate(path);
          }
        }, [user, navigate]);

        if(!advertiserCanAccessRecruitTab(user)) {
          return <ErrorPage errorCode={403} />;
        }

        return (
          <Nav2022ViewProvider user={user}>
            <PublishersSearch
              partnersTable={<FavoritePartnersTable />}
              onCancel={() => {
                redirectToMemberUrl();
              }}
            />
          </Nav2022ViewProvider>
        );
      },
    },
    {
      path: PUBLISHER_PROFILE_PATH_PATTERN,
      titleMessage: {
        ...messages.appTitle,
      },
      Content: ({ user }) => {
        const navigate = usePlatformNavigate();

        useEffect(() => {
          if (userIsPublisher(user)) {
            const companyId = user?.currentCompany?.id;
            const path = generatePath(PUBLISHER_PROFILE_PATH_PATTERN, {
              id: companyId,
            });
            navigate(path);
          }
        }, [user, navigate]);

        return (
          <Nav2022ViewProvider user={user}>
            <Profile
              onCancel={() => {
                redirectToMemberUrl();
              }}
              tabIndex={OVERVIEW_TAB_INDEX}
            />
          </Nav2022ViewProvider>
        );
      },
    },
    {
      path: PUBLISHER_PROFILE_PROPERTIES_LIST_PATH_PATTERN,
      titleMessage: {
        ...messages.appTitle,
      },
      Content: ({ user }) => {
        const navigate = usePlatformNavigate();
        
        useEffect(() => {
          if (userIsPublisher(user)) {
            const companyId = user?.currentCompany?.id;
            const path = generatePath(PUBLISHER_PROFILE_PROPERTIES_LIST_PATH_PATTERN, {
              id: companyId,
            });
            navigate(path);
          }
        }, [user, navigate]);

        return (
          <Nav2022ViewProvider user={user}>
            <Profile
              onCancel={() => {
                redirectToMemberUrl();
              }}
              tabIndex={PROPERTIES_TAB_INDEX}
            />
          </Nav2022ViewProvider>
        );
      },
    },
    {
      path: PUBLISHER_PROFILE_PROPERTY_DETAIL_PATH_PATTERN,
      titleMessage: {
        ...messages.appTitle,
      },
      Content: ({ user }) => {
        return (
          <Nav2022ViewProvider user={user}>
            <Profile
              onCancel={() => {
                redirectToMemberUrl();
              }}
              tabIndex={PROPERTIES_TAB_INDEX}
            />
          </Nav2022ViewProvider>
        );
      },
    },
    {
      path: "*",
      titleMessage: {
        ...messages.appTitle,
      },
      Content: ({ user }) => {
        return (
          <ErrorPage
            errorCode={404}
          />
        );
      },
    },
  ];

  const queryClient = new QueryClient();
  const platformDomains =
    window.location.host === "partners.cj.com"
      ? undefined
      : { members: "lab106" };

  return (
    <Nav2022Provider nav2022>
      <QueryClientProvider
        client={queryClient}
        config={{ queries: { refetchOnWindowFocus: false } }}
      >
        <AppProvider>
          <PlatformApp
            routes={routes}
            translations={translations}
            platformDomains={platformDomains}
          />
        </AppProvider>
      </QueryClientProvider>
    </Nav2022Provider>
  );
};
